import { createContext, useState } from "react";
import { SavedSearchDTO } from "./types";

type SavedSearchContextType = {
  state: SavedSearchDTO[];
  setState: React.Dispatch<React.SetStateAction<SavedSearchDTO[]>>;
};

const initialSavedSearchState = [];
export const SavedSearchContext = createContext<SavedSearchContextType>({
  state: initialSavedSearchState,
  setState: () => [],
});

export const SavedSearchProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [state, setState] = useState<SavedSearchDTO[]>(initialSavedSearchState);
  return (
    <SavedSearchContext.Provider value={{ state, setState }}>
      <>{children}</>
    </SavedSearchContext.Provider>
  );
};
