import { z } from "zod";
import { useZodls } from "./useZodls";

const ChipSchema = z.object({
  alias: z.optional(
    z.object({ input: z.array(z.string()), weight: z.number() })
  ),
  /* Number for recent tab where the array index is used on the chip to lookup all the chips for the entry */
  es_id: z.union([z.string(), z.number()]),
  // id for skills is a string
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  path: z.optional(z.array(z.number())),
  type: z.enum([
    "freetext",
    "freetextInSuggestSearch",
    "role",
    "industry",
    "location",
    "distance_in_miles",
    "company",
    "skill",
    "recent",
    "saved",
    "seniority",
    "skill",
    "sector",
  ]),
  //for when multiple values are required for the filter
  extra: z.optional(z.array(z.string())),
  /* Computed ui side and used for grouping */
  isFamily: z.optional(z.boolean()),
  // used for talent sourcing
  boolean_operator: z.optional(z.enum(["and", "or", "not"])),
  freetext: z.optional(z.boolean()),
});

/**
 * This schema represents the data stored in localStorage
 */
const SourcingFilterSchema = z.object({
  company: z.array(ChipSchema),
  industry: z.array(ChipSchema),
  skill: z.array(ChipSchema),
  location: z.array(ChipSchema),
  role: z.array(ChipSchema),
  jobTitles: z.array(ChipSchema),
  freetext: z.optional(z.array(ChipSchema)),
  distanceInMiles: z.optional(z.number()),
  monthsInRoleMin: z.optional(z.number()),
  monthsInRoleMax: z.optional(z.number()),
  monthsExperienceMin: z.optional(z.number()),
  monthsExperienceMax: z.optional(z.number()),
  seniority: z.array(ChipSchema),
  applicability: z.array(z.enum(["current", "previous"])),
  companySizes: z.array(
    z.enum([
      "1-10",
      "11-50",
      "51-200",
      "201-500",
      "501-1000",
      "1001-5000",
      "5001-10000",
      "10001-9999999",
    ])
  ),
  likelyToChange: z.optional(z.boolean()),
  exclusion: z.optional(z.array(ChipSchema)),
  phoneNumberType: z.optional(
    z.array(z.enum(["All", "DIRECT_DIAL", "MOBILE", "COMPANY_SWITCHBOARD"]))
  ),
  phoneNumberScore: z.optional(z.number()),
  emailScore: z.optional(z.number()),
});

export type SourcingFilterType = z.infer<typeof SourcingFilterSchema>;

export const defaultSourcingFilter: SourcingFilterType = {
  company: [],
  industry: [],
  skill: [],
  location: [],
  role: [],
  jobTitles: [],
  freetext: [],
  seniority: [],
  applicability: ["current"],
  likelyToChange: false,
  companySizes: [],
  distanceInMiles: 1,
  phoneNumberType: [],
  phoneNumberScore: 0,
  emailScore: 0,
};

const SOURCING_FILTER_STORAGE_KEY = "sourcing_filter";

/**
 * Hook to access the localStorage backed data for sourcing filters
 */
export const useSourcingFilter = () =>
  useZodls(
    SOURCING_FILTER_STORAGE_KEY,
    SourcingFilterSchema,
    defaultSourcingFilter
  );

/**
 * Simple helper to remove the stored values for the filter from localStorage
 */
export const clearSourcingFilterStorage = () => {
  localStorage.removeItem(SOURCING_FILTER_STORAGE_KEY);
};
