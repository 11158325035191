//@ts-nocheck
import { createSelector } from "reselect";
import { getUserId, getIsTrialExpired } from "store/auth/selectors";
import getOr from "lodash/fp/getOr";
import some from "lodash/some";
import { initialState } from "store/searches/reducer";

const getSearchResults = (state: GlobalState) => {
  if (state.searches && state.searches.results) {
    return state.searches.results;
  }

  return initialState.results;
};

export const getSearchSuggestions = getOr(
  initialState.suggested,
  "searches.suggested"
);

export const getSearchTerms = (state: GlobalState) => {
  if (state.searches && state.searches.searchTerms) {
    return state.searches.searchTerms;
  }

  return initialState.searchTerms;
};

export const getExcluded = (state: GlobalState) => {
  let excluded = initialState.excluded;

  if (state.searches && state.searches.excluded) {
    excluded = state.searches.excluded;
  }

  return excluded;
};

export const getRadius = (state: GlobalState) => {
  if (state.searches && state.searches.radius) {
    return state.searches.radius;
  }
  return initialState.radius;
};

export const getPowerBarOpenState = (state: GlobalState) => {
  return state.searches.isPowerBarOpen;
};

export const getIncluded = (state) => {
  const searchTerms = getSearchTerms(state);
  const exclusions = getExcluded(state);
  return searchTerms.filter((item) => !some(exclusions, item));
};

export const getSearchFilters = (state: GlobalState, tab: EventSearchType) => {
  if (
    state.searches &&
    state.searches.filterOptions &&
    state.searches.filterOptions[tab]
  ) {
    return state.searches.filterOptions[tab];
  }

  return {};
};

export const getTotalPages = (state, type) =>
  createSelector(getSearchResults, (result) =>
    result[type] ? result[type].totalPages : 1
  )(state);

export const getCurrentPage = (state, type) =>
  createSelector(getSearchResults, (result) =>
    result[type] ? result[type].page : 1
  )(state);

export const getDocuments = (state: GlobalState, type: EventSearchType) => {
  const searchResults = getSearchResults(state);
  let results: SearchState["results"]["bdl"]["documents"] = [];

  if (searchResults[type] && searchResults[type].documents) {
    results = searchResults[type].documents;
  }

  return results;
};

export const getEventOptions = (state: GlobalState, type: EventSearchType) =>
  createSelector(getSearchResults, (results) =>
    results[type] && results[type].events ? results[type].events : []
  )(state);

export const getSuggestedDocuments = (state, type) =>
  createSelector(
    getSearchSuggestions,
    (suggested) => (suggested[type] && suggested[type].events) || []
  )(state);

export const getSuggestedManagers = createSelector(
  getSearchSuggestions,
  (suggested) => (suggested.bdl ? suggested.bdl.managers : [])
);

export const buildSearchObject = (
  state: GlobalState,
  tab: EventSearchType
): GetUpdatedResultsSearchCriteria => {
  const userId = getUserId(state);
  const exclusions = getExcluded(state);
  const inclusions = getIncluded(state);
  return {
    search: inclusions,
    excluded: exclusions,
    userId,
    eventsLock: getEventOptions(state, tab),
    radius: getRadius(state),
    tab,
    ...getSearchFilters(state, tab),
  };
};

const leadsFilterReducer = (
  filter: <T>(item: T) => T,
  prev: unknown[],
  curr: EventResult
) => {
  return prev.concat(
    curr &&
      curr.leads &&
      curr.leads.map((lead) => {
        // NOTE(ersenal): Resolve the lead office
        const office =
          curr.company_details &&
          curr.company_details.offices &&
          curr.company_details.offices.find(
            (s) => s.city_id === lead.location_id
          );

        return filter({
          eventId: curr.event_id,
          eventDate: curr.timestamp_published,
          eventScore: curr.score,
          eventAnalysis: curr.summary,
          companyIndustries:
            curr.company_details &&
            curr.company_details.industries &&
            curr.company_details.industries.map((s) => s.name),
          leadLocation:
            lead.location_details && lead.location_details.name_accent,
          leadRoles:
            lead.roles_details &&
            lead.roles_details
              .filter((s) => s.role_type === "function")
              .map((s) => s.name),
          parentRoles:
            lead.roles_details &&
            lead.roles_details
              .filter((s) => s.role_type === "family")
              .map((s) => s.name),
          companyName:
            curr.company_details && curr.company_details.display_name,
          companyUrl:
            curr.company_details &&
            curr.company_details.urls &&
            curr.company_details.urls.homepage,
          companyId: curr.company_id,
          hiringManager: lead.hiring_manager_details,
          hrManager: lead.hr_manager_details,
          switchboardNumber: office && office.phone_number,
          inPipeline: curr.is_in_pipeline,
        });
      })
  );
};

export const getRows = (state: GlobalState, suggested?: boolean) => {
  const searchRoles = getSearchTerms(state)
    .filter((s) => s.type === "role")
    .map((s) => s.name);

  const rolesFilter = (lead) => {
    let leadRoles;
    if (lead.leadRoles.length === 1) {
      leadRoles = [lead.leadRoles[0]];
    } else {
      leadRoles = lead.leadRoles.filter(
        (role) => searchRoles.indexOf(role) !== -1
      );
    }

    if (!leadRoles.length && lead.parentRoles && lead.parentRoles.length) {
      leadRoles = lead.parentRoles.filter(
        (role) => searchRoles.indexOf(role) !== -1
      );
    }
    return {
      ...lead,
      leadRoles,
    };
  };

  const filter = searchRoles.length > 0 ? rolesFilter : (lead) => lead;

  const filteredReducer = leadsFilterReducer.bind(null, filter);
  const documents = getDocuments(state, "bdl").reduce(filteredReducer, []);
  const suggestedDocuments = getSuggestedDocuments(state, "bdl").reduce(
    filteredReducer,
    []
  );

  const documentsToSend = suggested ? suggestedDocuments : documents;
  return documentsToSend;
};

export const getRecentSearch = (state: GlobalState): RecentSearch => {
  let result: RecentSearch;

  if (state && state.searches) {
    const { excluded, searchTerms, recentSearches } = state.searches;

    if (searchTerms && searchTerms.length) {
      result = { chips: searchTerms, chips_exclusion: excluded }; // Last search terms used
    } else if (recentSearches && recentSearches.length) {
      result = recentSearches[recentSearches.length - 1]; // Last recent search
    }
  }

  return result;
};
