import * as Sentry from "@sentry/browser";
import { requestUrl } from "utils/api-request";
import request from "utils/request";

export const putSignaturesApi = async (
  rawSignature: string,
  signature: string
) => {
  try {
    await request({
      url: requestUrl({
        service: "v2-email-integration",
        action: "signature",
      }),
      options: {
        method: "PUT",
        body: JSON.stringify({
          content: encodeURIComponent(signature), // Deprecated by the raw functionality 2021-10-15
          content_raw: window.encodeURIComponent(rawSignature),
        }),
      },
      auth: true,
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};
