import { requestUrl } from "utils/api-request";
import request from "utils/request";

export const removeIntegrationApi = async (
  userId: string,
  integrationType: "gsuite" | "outlook"
): Promise<void> => {
  await request({
    url: requestUrl({
      service: "v2-email-integration",
      action: `remove_integration`,
    }),
    options: {
      method: "DELETE",
      body: JSON.stringify({
        seat_user_id: userId,
        integration_type: integrationType,
      }),
    },
    auth: true,
  });
};
