import { createContext, Dispatch, useEffect, useReducer } from "react";
import { useRouter } from "next/router";
import {
  Actions,
  initialFilterState,
  sourcingFilterReducer,
  SourcingState,
} from "./reducer";
import {
  defaultSourcingFilter,
  SourcingFilterType,
  useSourcingFilter,
} from "lib/localstorage";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export type SourcingFilterContextType = {
  state: SourcingState;
  dispatch: Dispatch<Actions>;
  storedFilters: SourcingFilterType;
  saveFilters: (value: SourcingFilterType) => void;
};

export const SourcingFilterContext = createContext<SourcingFilterContextType>({
  state: initialFilterState,
  dispatch: undefined,
  storedFilters: defaultSourcingFilter,
  saveFilters: () => {},
});

export const SourcingFilterProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const [state, dispatch] = useReducer(
    sourcingFilterReducer,
    initialFilterState
  );
  const [storedFilters, saveFilters] = useSourcingFilter();

  useEffect(
    function onPageChange() {
      dispatch({ type: "init", payload: storedFilters });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want this to retrigger when storedFilters changes
    [router.asPath]
  );

  return (
    <SourcingFilterContext.Provider
      value={{ state, dispatch, storedFilters, saveFilters }}
    >
      {children}
    </SourcingFilterContext.Provider>
  );
};
