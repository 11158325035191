import { assign, createMachine, State } from "xstate";
import { PipelineTicketContact } from "components/Pipeline/PipelineTicket/Shared";
import { OutboxMessageRecipient } from "components/Contact/types";
import { ContactEmail } from "@talentticker/tt-utils";

export type MessageRecipient = Contact &
  PipelineContact &
  PipelineTicketContact &
  MarketMappingPeopleResult &
  OutboxMessageRecipient & {
    /**
     * User selected email ready for use in overwriting
     * system derived email
     */
    selectedEmail?: string;
    emails: ContactEmail[];
  };

/**
 * Partial is used here to signify that the recipient may be unrevealed
 */
export type Recipient = Partial<MessageRecipient>;

export type RecipientsMachineState = {
  recipients: Recipient[];
};

type RevealRecipientEvent = { type: "REVEAL_RECIPIENT"; recipient: Recipient };
type ClearRecipientsEvent = {
  type: "CLEAR_RECIPIENTS";
  recipients: Recipient[];
};
/**
 * @deprecated
 * This action should no longer be used for messaging
 * Instead the MessagingMachine takes ownership over specifically selected email/recipient selections
 */
type UpdateRecipientEmailEvent = {
  type: "UPDATE_RECIPIENT_EMAIL";
  id: Recipient["id"];
  email: string;
};
type RemoveRecipientEvent = { type: "REMOVE_RECIPIENT"; id: string };
export type RecipientsMachineActions =
  | RemoveRecipientEvent
  | { type: "TOGGLE_RECIPIENT"; recipient: Recipient }
  | { type: "ADD_RECIPIENTS"; recipients: Recipient[] }
  | { type: "SET_RECIPIENTS"; recipients: Recipient[] }
  | { type: "CLEAR_ALL_RECIPIENTS" }
  | ClearRecipientsEvent
  | RevealRecipientEvent
  | UpdateRecipientEmailEvent;

export type IRecipientMachineState = State<
  RecipientsMachineState,
  RecipientsMachineActions
>;

/**
 * State machine responsible for managing the recipients selected for
 * messaging or export etc
 */
export const RecipientsMachine = createMachine(
  {
    id: "recipients-machine",
    context: {
      recipients: [],
    },
    tsTypes: {} as import("./RecipientsMachine.typegen").Typegen0,
    schema: {
      context: {} as RecipientsMachineState,
      events: {} as RecipientsMachineActions,
    },
    on: {
      ADD_RECIPIENTS: {
        actions: "addRecipients",
      },
      SET_RECIPIENTS: {
        actions: "addRecipients",
      },
      REMOVE_RECIPIENT: {
        actions: "removeRecipientById",
      },
      TOGGLE_RECIPIENT: [
        {
          // recipient already selected
          cond: (context, event) =>
            Boolean(
              context.recipients.find((r) => r.id === event.recipient.id)
            ),
          actions: "removeRecipient",
        },
        {
          // otherwise add the recipient
          actions: "addRecipient",
        },
      ],
      REVEAL_RECIPIENT: {
        actions: "revealRecipient",
      },
      CLEAR_RECIPIENTS: {
        actions: "clearRecipients",
      },
      CLEAR_ALL_RECIPIENTS: {
        actions: "clearAllRecipients",
      },
      UPDATE_RECIPIENT_EMAIL: {
        actions: "updateRecipientEmail",
      },
    },
  },
  {
    actions: {
      addRecipients: assign({
        recipients: (context, { recipients }) => {
          // only add recipients if they have not already been added
          const newRecipients = recipients.filter(
            (r) => !context.recipients.find((re) => r.id === re.id)
          );
          return [...context.recipients, ...newRecipients];
        },
      }),
      addRecipient: assign({
        recipients: (context, event) => [
          ...context.recipients,
          event.recipient,
        ],
      }),
      removeRecipientById: assign({
        recipients: (context, event) =>
          context.recipients.filter((r) => r.id !== event.id),
      }),
      removeRecipient: assign({
        recipients: (context, event) =>
          context.recipients.filter((r) => r.id !== event.recipient.id),
      }),
      revealRecipient: assign<RecipientsMachineState, RevealRecipientEvent>({
        recipients: (context, event) =>
          context.recipients.map((r) =>
            r.id === event.recipient.id ? { ...r, ...event.recipient } : r
          ),
      }),
      clearRecipients: assign<RecipientsMachineState, ClearRecipientsEvent>({
        recipients: (context, { recipients }) =>
          context.recipients.filter(
            (r) => !recipients.find((re) => re.id === r.id)
          ),
      }),
      clearAllRecipients: assign({
        recipients: [] as Recipient[],
      }),
      /**
       * @deprecated
       */
      updateRecipientEmail: assign({
        recipients: (context, { id, email }) =>
          context.recipients.map((r) =>
            r.id === id ? { ...r, selectedEmail: email } : r
          ),
      }),
    },
  }
);
