import { REQUEST_METHOD } from "../../constants";
import { requestUrl } from "utils/api-request";
import request from "utils/request";
import { FetchResponse, requestV2 } from "api/request";
import { MessageRecipient } from "./MessagingContext";
import { THEME } from "env";

export type MessageAttachment = {
  name: string;
  size: number;
  content: string; //this is a string of html to fill email
  type?: string; //file type
};

export const GetMessagingConfiguration = async () => {
  const res = await request({
    url: requestUrl({
      service: "v2-email-integration",
      action: `get_accounts?product=${THEME.toLocaleLowerCase()}`,
    }),
    options: {
      method: REQUEST_METHOD.GET,
    },
    auth: true,
  });

  const data = res.data as {
    gsuite?: {
      user_email: string;
    };
    smtp?: {
      user_email: string;
    };
    outlook: {
      configuration_url: string;
      has_account: boolean;
      user_email: string;
    };
  };

  return {
    isConfigured:
      (data.gsuite && !!data.gsuite.user_email) ||
      !!data?.smtp?.user_email ||
      (data.outlook && data.outlook.has_account),

    gsuiteEmail: data.gsuite && data.gsuite.user_email,
    smtpUsername: data?.smtp?.user_email,
    outlookConfigurationUrl: data.outlook && data.outlook.configuration_url,
    outlookConfigured: data.outlook && data.outlook.has_account,
    outlookEmail: data.outlook && data.outlook.user_email,
  };
};

export type ConfigureMessagingErrorReturn = {
  correlation_id: string;
  error: string;
  error_codes: number[];
  error_description: string;
  error_uri: string;
  timestamp: string;
  trace_id: string;
};

export const ConfigureMessaging = async (
  code: string
): Promise<
  FetchResponse<Record<string, any>, ConfigureMessagingErrorReturn>
> => {
  return await requestV2({
    url: "/v2-email-integration/outlook_authorise",
    body: JSON.stringify({ code, product: THEME.toLocaleLowerCase() }),
    method: REQUEST_METHOD.POST,
  });
};

type MessageResponse = {
  messsage: string;
};

type IntegrationTypes = "gsuite" | "outlook" | "SMTP";

type SendMessageProps = {
  subject: string;
  /**
   * @deprecated
   */
  contactIds?: Guid[];
  recipients?: Partial<MessageRecipient>[];
  content: string;
  attachments: MessageAttachment[];
  type?: IntegrationTypes;
  sequenceId?: string;
  isThreaded?: boolean;
};

export const SendMessage = async ({
  subject,
  recipients,
  content,
  attachments,
  sequenceId,
  isThreaded,
  type = "outlook",
}: SendMessageProps): Promise<{ success: boolean; errorMessage?: string }> => {
  if (content === null) {
    return { success: false };
  }
  const sendEmailUrl = "/v2-email-integration/incoming_email_handler";
  const res = await requestV2<MessageResponse>({
    url: sendEmailUrl,
    method: REQUEST_METHOD.POST,
    body: JSON.stringify({
      recipients,
      subject,
      content,
      attachments,
      integration_type: type,
      is_threaded: isThreaded,
      sequence_id: sequenceId,
    }),
  });
  // Handle Payload too large..
  if (res.status === 413) {
    return {
      success: false,
      errorMessage:
        "The email body was too large to send, please remove attachments or images and try again",
    };
  }
  // Handles errors with incorrect formating of email and when HTML content strip can't be read
  if (res.status === 400 && res.data.errors[0] === null) {
    return {
      success: false,
      errorMessage:
        res.data.errors[0] ??
        "Unable to send email, please check your template formatting and try again",
    };
  }
  if (res.status === 200) {
    return { success: true };
  } else {
    const errorMessage = res.data.errors[0];
    return {
      success: false,
      errorMessage,
    };
  }
};
