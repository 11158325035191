import getOr from "lodash/fp/getOr";
import { initialState } from "store/pipeline/reducer";
import { createSelector } from "reselect";
import { TITLES } from "store/pipeline/constants";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

const typeLookUp = {
  col1: "PROSPECT",
  col2: "CONTACTED",
  col3: "ACTIVE",
  col4: "SUCCESS",
};

const fieldNameLookup = {
  deal_value: "deal value",
  text: "description",
  record_title: "title",
};

export const getColumDealTotals = (column) =>
  column
    .map((task) => task.deal_value)
    .reduce((sum, current) => sum + current, 0)
    .toLocaleString();

const getActionText = (event, users) => {
  const userName = users[event.user_id] && users[event.user_id].user_name;
  switch (event.event_type) {
    case "CreateRecord":
      return `${userName} added this ticket to the Projects`;
    case "UpdateRecord":
      return `${userName} edited the ticket ${
        fieldNameLookup[event.field_name]
      }`;
    case "ChangeColumn":
      return `${userName} moved this ticket to ${TITLES[event.new_col]}`;
    case "CreateNote":
      return `${userName} added a note to this ticket`;
    case "DeleteRecord":
      return `${userName} deleted this ticket`;
    case "UndeleteRecord":
      return `${userName} reopened this ticket`;
    case "AssignRecord": {
      const assignee = users[event.assigned_user_id].user_name;
      return `${userName} assigned this ticket to ${assignee}`;
    }
    //currently not using: UpdateNote, DeleteNote
  }
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const getColumns = getOr(initialState.columns, "pipeline.columns");
const getCurrentRecord = getOr(initialState.current, "pipeline.current");

export function getUserPipelineData(state) {
  const columns = getColumns(state);
  const tasks = [].concat(...Object.values(columns));
  const totalTasks = tasks.length;

  const formattedColumns = Object.keys(columns).reduce((acc, column) => {
    const columnId = getKeyByValue(typeLookUp, column);
    return {
      ...acc,
      [columnId]: {
        id: columnId,
        title: column,
        taskIds: columns[column].map((task) => task.pipeline_record_uuid),
        dealTotals: getColumDealTotals(columns[column]),
      },
    };
  }, {});

  const formattedTasks = tasks.reduce(
    (acc, task) => ({
      ...acc,
      [task.pipeline_record_uuid]: {
        id: task.pipeline_record_uuid,
        companyUrl: task.company_url,
        companyName: task.company_display_name,
        title: task.record_title || task.event_title,
        dealValue: task.deal_value && task.deal_value.toLocaleString(),
        timestamp: task.timestamp_created,
        eventSummary: task.event_summary,
        contacts: task.contacts,
        eventId: task.event_id,
      },
    }),
    {}
  );

  return {
    columns: formattedColumns,
    tasks: formattedTasks,
    taskCount: totalTasks,
  };
}

export const getColumnType = (colId) => typeLookUp[colId];

const formatHistory = (history, users) =>
  history.map((event) => ({
    photo: users[event.user_id] && users[event.user_id].user_image,
    timestamp: format(parseISO(event.timestamp), "dd/MM/yyyy, h:mma"),
    text: getActionText(event, users),
  }));

const formatNotes = (notes, users = []) =>
  notes.map((note) => ({
    id: note.note_uuid,
    userPhoto:
      users[note.created_by_user_id] &&
      users[note.created_by_user_id].user_image,
    userName:
      users[note.created_by_user_id] &&
      users[note.created_by_user_id].user_name,
    text: note.text,
    timestamp: format(parseISO(note.timestamp_created), "dd/MM/yyyy, h:mma"),
  }));

const formatRecord = (data) => ({
  dealValue: data.record && data.record.deal_value,
  description:
    (data.record && data.record.record_text) ||
    (data.record && data.record.event_summary),
  eventId: data.record && data.record.event_id,
  history:
    data.record && formatHistory(data.record.history, data.referenced_users),
  notes: data.record && formatNotes(data.record.notes, data.referenced_users),
  title:
    (data.record && data.record.record_title) ||
    (data.record && data.record.event_title),
  assignedUserId:
    data.record &&
    (data.record.assigned_user_id || data.record.created_by_user_id),
});

export const getPipelineRecordData = createSelector(
  getCurrentRecord,
  (record) => record && formatRecord(record.data)
);

export const getPipelineRecordLoading = createSelector(
  getCurrentRecord,
  (record) => record.loading
);
