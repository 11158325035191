import * as Sentry from "@sentry/browser";
import {
  actions,
  ActorRefWithDeprecatedState,
  assign,
  createMachine,
  send,
  spawn,
  State,
} from "xstate";
import { PrettyDecentFile } from "@talentticker/tt-editor";
import { MessageAttachment, SendMessage } from "./MessagingApi";
import {
  RecipientsMachine,
  RecipientsMachineActions,
  RecipientsMachineState,
} from "./RecipientsMachine";

export type SelectedRecipient = {
  manager_id: string;
  /**
   * If not provided, the backend will pick the best email to send to
   */
  email?: string;
  /**
   * Flag for if the recipient was picked automatically
   */
  auto?: boolean;
};

export type DraftState = {
  subject: string;
  message: string;
  isThreaded?: boolean;
  attachments: PrettyDecentFile[];
  images: PrettyDecentFile[];
  /**
   * The selected sequence to add the message to
   */
  sequenceId?: string;
  templateId?: string;
};

export type MessageMachineState = {
  draft: DraftState;
  signature?: string;
  /**
   * Reference to the child RecipientsMachine
   */
  recipientsRef?: ActorRefWithDeprecatedState<
    RecipientsMachineState,
    RecipientsMachineActions,
    any,
    any
  >;
  selectedRecipients: SelectedRecipient[];
  error: string | null;
  /**
   * Flags and configuration settings
   * Passed to the machine at runtime
   */
  config: {
    // flags
    sequencing?: boolean;
    // user specific
    gsuiteEmail?: string;
    smtpUsername?: string;
  };
};

const initialState: MessageMachineState = {
  draft: {
    subject: "",
    message: "",
    attachments: [],
    images: [],
  },
  error: null,
  config: {},
  selectedRecipients: [],
};

/**
 * XState just need a bit of a helping hand with some of these type hints
 */
type UpdateDraftEvent = { type: "UPDATE_DRAFT"; draft: Partial<DraftState> };
type AddAttachmentsEvent = {
  type: "ADD_ATTACHMENTS";
  attachments: PrettyDecentFile[];
};
type RemoveAttachmentEvent = {
  type: "REMOVE_ATTACHMENT";
  attachment: PrettyDecentFile;
};
type SetSignatureEvent = { type: "SET_SIGNATURE"; signature?: string };
type SetConfigEvent = {
  type: "SET_CONFIG";
  config: MessageMachineState["config"];
};
type SelectedRecipientEmailEvent = {
  type: "SELECT_RECIPIENT_EMAIL";
  id: string;
  email: string;
};
type DeselectedRecipientEmailEvent = {
  type: "DESELECT_RECIPIENT_EMAIL";
  id: string;
  email: string;
};
type SetRecipientEmailAutoEvent = {
  type: "SET_RECIPIENT_EMAIL_AUTO";
  id: string;
};
export type MessageMachineActions =
  | { type: "SEND_EMAIL" }
  | { type: "SET_OPEN"; isOpen: boolean }
  | UpdateDraftEvent
  /**
   * Resets draft state back to just a signature
   */
  | { type: "CLEAR_DRAFT" }
  | AddAttachmentsEvent
  | RemoveAttachmentEvent
  | { type: "CLEAR_ATTACHMENTS" }
  | { type: "CLEAR_ERRORS" }
  | SetSignatureEvent
  | SetConfigEvent
  | RecipientsMachineActions
  | SelectedRecipientEmailEvent
  | DeselectedRecipientEmailEvent
  | SetRecipientEmailAutoEvent
  | { type: "GO_TO_EMAIL_SELECTION" }
  | { type: "NEXT" };

export type IMessagingMachineState = State<
  MessageMachineState,
  MessageMachineActions
>;

/**
 * TODO: move message modal open/close state to be handled solely by this machine
 */
export const MessagingMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5gF8A0IB2B7CdGgAoBbAQwGMALASwzAEp8QAHLWKgFyqw0YA9EAjACZ0AT0FDkU5EA */
  createMachine(
    {
      id: "messaging-machine",
      context: initialState,
      tsTypes: {} as import("./MessagingMachine.typegen").Typegen0,
      schema: {
        context: {} as MessageMachineState,
        events: {} as MessageMachineActions,
        services: {} as { sendEmail: { data: unknown } },
      },
      initial: "idle",
      states: {
        idle: {
          initial: "startup",
          states: {
            /**
             * Spawn the child machine at start
             * This machine will live for the lifetime of this machine
             */
            startup: {
              entry: [
                assign({
                  recipientsRef: () =>
                    spawn(RecipientsMachine, {
                      name: "recipients",
                      sync: true,
                    }),
                }),
                actions.log(() => "Initializing recipients machine"),
              ],
            },
            closed: {},
            open: {},
            emailSelection: {
              on: {
                NEXT: {
                  target: "open",
                  actions: actions.log(() => `NEXT called, going to open`),
                },
              },
            },
          },
          on: {
            UPDATE_DRAFT: {
              actions: assign<MessageMachineState, UpdateDraftEvent>({
                draft: (context, { draft }) => ({
                  ...context.draft,
                  ...draft,
                }),
              }),
            },
            CLEAR_DRAFT: {
              actions: assign<MessageMachineState>({
                draft: (context) => ({
                  ...initialState.draft,
                  message: context.signature || initialState.draft.message,
                }),
              }),
            },
            SEND_EMAIL: "sending",
            ADD_RECIPIENTS: {
              actions: ["passToRecipientsRef", "addNewRecipientsWithAuto"],
            },
            SET_RECIPIENTS: {
              actions: ["passToRecipientsRef", "setSelectedRecipientsToAuto"],
            },
            REMOVE_RECIPIENT: {
              actions: ["passToRecipientsRef", "removeSelectedRecipient"],
            },
            /**
             * @deprecated -- use add and clear as they are more explicit
             */
            TOGGLE_RECIPIENT: {
              actions: "passToRecipientsRef",
            },
            REVEAL_RECIPIENT: {
              actions: "passToRecipientsRef",
            },
            CLEAR_RECIPIENTS: {
              actions: ["passToRecipientsRef", "clearSelectedRecipients"],
            },
            CLEAR_ERRORS: {
              actions: ["clearErrors"],
            },
            CLEAR_ALL_RECIPIENTS: {
              actions: ["passToRecipientsRef", "clearAllSelectedRecipients"],
            },
            /**
             * @deprecated
             * Superceded by SELECT_RECIPIENT_EMAIL
             */
            UPDATE_RECIPIENT_EMAIL: {
              actions: "passToRecipientsRef",
            },
            SELECT_RECIPIENT_EMAIL: {
              cond: (context, { email, id }) =>
                !context.selectedRecipients.find(
                  (s) => s.manager_id === id && s.email === email
                ),
              actions: "selectRecipientEmail",
            },
            DESELECT_RECIPIENT_EMAIL: {
              actions: "deselectRecipientEmail",
            },
            SET_RECIPIENT_EMAIL_AUTO: {
              actions: "setRecipientEmailAuto",
            },
            ADD_ATTACHMENTS: {
              actions: assign<MessageMachineState, AddAttachmentsEvent>({
                draft: ({ draft }, { attachments }) => {
                  // we only want to add attachments which have not already been added
                  const newAttachments = attachments.filter(
                    (a) => !draft.attachments.find((at) => a.id === at.id)
                  );
                  return {
                    ...draft,
                    attachments: [...draft.attachments, ...newAttachments],
                  };
                },
              }),
            },
            REMOVE_ATTACHMENT: {
              // check attachment is in the draft
              cond: ({ draft }, { attachment }) =>
                !!draft.attachments.find((a) => a.id === attachment.id),
              actions: assign<MessageMachineState, RemoveAttachmentEvent>({
                draft: (context, { attachment }) => ({
                  ...context.draft,
                  attachments: context.draft.attachments.filter(
                    (a) => a.id !== attachment.id
                  ),
                }),
              }),
            },
            CLEAR_ATTACHMENTS: {
              actions: assign({
                draft: (context: MessageMachineState) => ({
                  ...context.draft,
                  attachments: [],
                }),
              }),
            },
          },
        },
        sending: {
          invoke: {
            src: "sendEmail",
            onDone: [
              {
                cond: (context) => Boolean(context.draft.sequenceId),
                target: "sent.sequence",
                actions: [
                  // "clearAllSelectedRecipients", -- we need to keep them temporarily (so we can update the external state that this recipient has been sent to)
                  "clearDraft",
                  "clearErrors",
                ],
              },
              {
                target: "sent.singular",
                actions: [
                  "clearAllSelectedRecipients",
                  "clearDraft",
                  "clearErrors",
                ],
              },
            ],
            onError: {
              target: "error",
              actions: assign({
                error: (_, event) => {
                  return event.data.message;
                },
              }),
            },
          },
        },
        sent: {
          entry: [
            assign({
              draft: initialState.draft,
            }),
            send({ type: "CLEAR_ALL_RECIPIENTS" }, { to: "recipients" }),
          ],
          initial: "singular",
          // we differentiate between singular and sequence emails for whether to display warning about sequence times
          states: {
            singular: {},
            sequence: {
              invoke: {
                src: "refetchRecipients",
                onDone: {
                  actions: ["clearAllSelectedRecipients"],
                },
                onError: {
                  actions: ["clearAllSelectedRecipients"],
                },
              },
            },
          },
        },
        error: {
          id: "error",
          invoke: {
            src: async (context) => {
              /** Send the error to sentry */
              Sentry.captureException(context.error);
            },
            onDone: [
              {
                cond: (_context, _event, state) =>
                  state.state.matches({ idle: "open" }),
                target: "idle.open",
                actions: assign((context: MessageMachineState) => ({
                  ...context,
                })),
              },
              {
                target: "idle.closed",
                actions: assign((context: MessageMachineState) => ({
                  ...context,
                })),
              },
            ],
          },
        },
      },
      on: {
        SET_OPEN: [
          /**
           * Handles the case where the open event is called when already in draft state
           */
          {
            cond: (_, event, { state }) =>
              event.isOpen && state.matches({ idle: "open" }),
            target: "idle.open",
          },
          {
            cond: (_, event) => event.isOpen,
            target: "idle.emailSelection",
          },
          {
            cond: (_, event) => !event.isOpen,
            target: "idle.closed",
          },
        ],
        SET_SIGNATURE: {
          actions: assign<MessageMachineState, SetSignatureEvent>({
            signature: (_, event) => event.signature,
          }),
        },
        CLEAR_ERRORS: {
          actions: ["clearErrors"],
        },
        SET_CONFIG: [
          /**
           * If the user hasBullhorn, push them into the emailSelection state
           * unless already in the flow
           */
          {
            cond: (_, _e, { state }) => !state.matches({ idle: "open" }),
            actions: "setConfig",
            target: "idle.emailSelection",
          },
        ],
        /**
         * Transition to the email selection state
         * Only allowed if user has Bullhorn configured
         */
        GO_TO_EMAIL_SELECTION: {
          target: "idle.emailSelection",
        },
      },
    },
    {
      actions: {
        /**
         * passes the event straight through to the child machine
         */
        passToRecipientsRef: send((_, event) => event, { to: "recipients" }),
        setConfig: assign({
          config: (_, event) => event.config,
        }),
        addNewRecipientsWithAuto: assign({
          selectedRecipients: (context, { recipients }) => {
            return [
              ...context.selectedRecipients,
              ...recipients.map((r) => ({
                manager_id: r.id,
                auto: true,
              })),
            ];
          },
        }),
        setSelectedRecipientsToAuto: assign({
          selectedRecipients: (_, { recipients }) =>
            recipients.map((r) => ({
              manager_id: r.id,
              auto: true,
            })),
        }),
        removeSelectedRecipient: assign({
          selectedRecipients: (context, { id }) => {
            return context.selectedRecipients.filter(
              (r) => r.manager_id !== id
            );
          },
        }),
        clearDraft: assign({
          draft: initialState.draft,
        }),
        clearErrors: assign({
          error: null as null | string,
        }),
        clearSelectedRecipients: assign({
          selectedRecipients: (context, event) =>
            context.selectedRecipients.filter(
              (s) => !event.recipients.find((r) => r.id === s.manager_id)
            ),
        }),
        clearAllSelectedRecipients: assign({
          selectedRecipients: () => [],
        }),
        selectRecipientEmail: assign({
          selectedRecipients: (context, { email, id }) => [
            ...context.selectedRecipients.filter(
              (r) => r.manager_id !== id || !r.auto
            ),
            { email, manager_id: id },
          ],
        }),
        deselectRecipientEmail: assign({
          selectedRecipients: (context, { email, id }) => {
            const others = context.selectedRecipients.filter(
              (s) => s.manager_id !== id || s.email !== email
            );
            const noOtherRecipient = !others.find((r) => r.manager_id === id);
            if (noOtherRecipient) {
              return [...others, { manager_id: id, auto: true }];
            }
            return [...others];
          },
        }),
        setRecipientEmailAuto: assign({
          selectedRecipients: (context, { id }) => {
            const others = context.selectedRecipients.filter(
              (s) => s.manager_id !== id
            );
            return [...others, { manager_id: id, auto: true }];
          },
        }),
      },
      services: {
        sendEmail: async (context) => {
          const { gsuiteEmail, smtpUsername, sequencing } = context.config;

          const useGsuite = !!gsuiteEmail;
          const useSMTP = !!smtpUsername;

          const { draft, selectedRecipients } = context;
          const {
            subject,
            message,
            attachments: files,
            isThreaded,
            sequenceId,
          } = draft;
          const attachments = files.map((attachment) => ({
            // this splits a url before the query params and after the final /
            content: (attachment.encodedUrl as string)
              ?.split("?")[0]
              .split("/")[3],
            name: attachment.file.name,
            type: attachment.file.type,
            size: attachment.file.size,
          })) as MessageAttachment[];

          const { success, errorMessage } = await SendMessage({
            subject,
            recipients: selectedRecipients,
            content: message,
            attachments,
            type: useGsuite ? "gsuite" : useSMTP ? "SMTP" : "outlook",
            sequenceId: sequencing ? sequenceId : undefined,
            isThreaded,
          });
          if (!success) {
            throw new Error(errorMessage);
          }
        },
      },
    }
  );
