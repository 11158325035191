import { useState, useEffect } from "react";
import { GetMessagingConfiguration } from "./MessagingApi";
import { Authorization } from "utils/AuthHelper";
import { useSelector } from "react-redux";
import { isLoggedIn } from "store/auth/selectors";
import { useUser } from "lib/auth/hook";

/**
 * This is a convenience hook to configure messaging. It takes a string value of the required feature in order to configure messages.
 *
 * @param requiredFeature - this is a string value of the required feature needed to configure messaging
 * @returns messagingConfigured
 * @returns messagingPermitted
 * @example
 *  const { messagingConfigured, messagingPermitted } = useMessagingConfiguration('messageEventContacts')
 *  const { messagingConfigured, messagingPermitted } = useMessagingConfiguration('messagePipelineContacts')
 */
export function useMessagingConfiguration() {
  const { permissions } = useUser();
  const loggedIn = useSelector(isLoggedIn);

  const [messagingConfigured, setMessagingConfigured] = useState(false);
  const [messagingPermitted, setMessagingPermitted] = useState(false);
  const [gsuiteEmail, setGsuiteEmail] = useState("");
  const [smtpUsername, setSmtpUsername] = useState("");

  useEffect(() => {
    async function configureMessaging() {
      const messageConfigured = await GetMessagingConfiguration();
      setMessagingConfigured(messageConfigured.isConfigured);
      setMessagingPermitted(Authorization.SendMessage(permissions));
      setGsuiteEmail(messageConfigured.gsuiteEmail);
      setSmtpUsername(messageConfigured.smtpUsername);
    }
    if (loggedIn) {
      configureMessaging();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, permissions]);

  return {
    messagingConfigured,
    messagingPermitted,
    gsuiteEmail,
    smtpUsername,
  };
}
