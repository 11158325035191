import { Dispatch, useContext } from "react";
import { MessageTemplate, MessageTemplatesContext } from "./context";
import { addSnackbarMessage } from "store/notices/actions";
import {
  deleteTemplatesApi,
  getTemplatesApi,
  patchTemplatesApi,
  postTemplatesApi,
} from "api/email-integration";

/**
 * This hook is to be used in conjunction with Message Template Configuration.
 * 
 * @returns ...state -- this is the accumulated state of MessageTemplateContext
   @returns dispatch -- dispatch an action to update state,
   @returns createTemplate -- convenience function to create a template,
   @returns updateTemplate -- convenience function to update a template,
   @returns deleteTemplate -- convenience function to delete a template,
   @returns fetchTemplates -- convenience function to fetch all user templates,
 */
export const useMessageTemplateContext = () => {
  const { state, dispatch } = useContext(MessageTemplatesContext);
  const reduxDispatch = dispatch as Dispatch<{ type: string; payload: any }>;

  const createTemplate = async (
    data: Parameters<typeof postTemplatesApi>[0]
  ) => {
    await postTemplatesApi(data);

    // After creating a template we need to fetch from server to ensure we have the uuid for editing
    await fetchTemplates();
  };

  const updateTemplate = async (
    data: Parameters<typeof patchTemplatesApi>[0]
  ) => {
    await patchTemplatesApi(data);
    dispatch({ type: "UPDATE_TEMPLATE", payload: data });
  };

  const fetchTemplates = async () => {
    const templates = await getTemplatesApi();
    dispatch({ type: "SET_TEMPLATES", payload: templates });
  };

  const deleteTemplate = async (templateId: MessageTemplate["id"]) => {
    await deleteTemplatesApi(templateId);
    dispatch({ type: "DELETE_TEMPLATE", payload: templateId });
  };

  const setActiveTemplate = (template: typeof state.templates[0]) => {
    dispatch({ type: "SET_ACTIVE_TEMPLATE", payload: template });
  };

  const selectTemplate = (id: string) => {
    const template = state.templates.find((template) => template.id === id);
    setActiveTemplate(template);
    if (!id) {
      resetActiveTemplate();
    }
  };

  const selectDefaultTemplate = () => {
    const active = (state.templates || []).find((template) => template.default);
    if (active) {
      dispatch({ type: "SET_ACTIVE_TEMPLATE", payload: active });
    }
  };

  const resetActiveTemplate = () => {
    dispatch({ type: "RESET_INTERACTIONS" });
  };

  const reportFailToUpdate = (errorMessage: string, isEditing: boolean) => {
    reduxDispatch(
      addSnackbarMessage({
        message:
          errorMessage ??
          `Failed to ${
            isEditing ? "update" : "create"
          } template, please try again`,
      })
    );
  };

  const setFormState = (changes: Partial<typeof state.formState>) => {
    dispatch({ type: "setFormState", payload: changes });
  };

  const resetFormState = () => {
    dispatch({ type: "RESET_INTERACTIONS" });
    resetActiveTemplate();
  };

  return {
    /* Data exposed */
    activeTemplate: state.activeTemplate,
    formState: state.formState,
    setFormState,
    templates: state.templates,
    hasDefaultTemplate: state.templates.some((x) => x.default),

    /* Functionality exposed */
    dispatch,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    fetchTemplates,
    selectTemplate,
    reportFailToUpdate,
    selectDefaultTemplate,
    setActiveTemplate,
    resetActiveTemplate,
    resetFormState,
  };
};
