import { Reducer } from "react";

type LengthCheckMiddlewareArgs<S, A> = {
  /**
   * The reducer that will be returned and executed
   */
  reducer: Reducer<S, A>;
  /**
   * The length of the array to check against
   */
  length: number;
  /**
   * this is the key of your state you want to check
   */
  entityKey: keyof S;
  /**
   * type - an action type to return when limit is exceeded
   */
  type: string;
};
export type ReactAction = { type: string; payload?: unknown };

/**
 * 
 * This is a utility middleware function that takes a reducer as an argument
 * Its goal is to check the length of a given array in state and return a determined action type
 * when that length is exceeded
 * 
 * @param reducer React reducer function
 * @param S - State Type used to type entity and passed to reducer
 * @param A - Action type passed to reducer
 * @returns reducer
 * 
 * @example
 *  const [state, dispatch] = useReducer(
        lengthCheckMiddleware({
          // just simply pass your typed reducer
          reducer: contactsFiltersReducer,
          length: 50,
          entityKey: 'selected',
          type: 'LIMIT_EXCEEDED'
        }),
        initialChips,
        init
      );
 * 
 * 
 */
export const lengthCheckMiddleware = <
  S extends { [key: string]: any },
  A extends ReactAction
>({
  reducer,
  length,
  entityKey,
  type,
}: LengthCheckMiddlewareArgs<S, A>) => (state: S, action: A) => {
  if (Array.isArray(state[entityKey])) {
    if (state[entityKey]?.length >= length) {
      // we want one less then the length so that the final entity can toggle the exceeded flag and get added to state
      return reducer(state, { type, payload: action.payload } as A);
    }
  } else {
    throw new Error(
      "entity is not an array.. please provide an array type to check length against"
    );
  }
  return reducer(state, action);
};
