import { requestUrl } from "utils/api-request";
import request from "utils/request";

export const deleteTemplatesApi = async (id: string) => {
  await request({
    url: requestUrl({
      service: "v2-email-integration",
      action: `templates/${id}`,
    }),
    options: {
      method: "DELETE",
    },
    auth: true,
  });
};
