import * as Sentry from "@sentry/browser";

import { requestUrl } from "utils/api-request";
import request from "utils/request";
import { getTemplatesApi } from "./getTemplatesApi";

type Data = ReturnTypeAsync<typeof getTemplatesApi>[0];

export const patchTemplatesApi = async (data: Data) => {
  try {
    await request({
      url: requestUrl({
        service: "v2-email-integration",
        action: `templates/${data.id}`,
      }),
      options: {
        method: "PATCH",
        body: JSON.stringify({
          ...data,
          body: window.encodeURIComponent(data.body), // Deprecated by the raw functionality 2021-10-15
        }),
      },
      auth: true,
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};
