import {
  lengthCheckMiddleware,
  ReactAction,
} from "lib/middleware/lengthCheckMiddleware";
import { useRouteChange } from "lib/onRouteChange";
import { useCallback } from "react";
import { createContext, Dispatch, useEffect, useReducer } from "react";
import { contactsFiltersReducer, ContactsModal } from "./effects";

type ContactsModalContextProps = {
  moreContactsVisible: boolean;
  visible: ContactsModal;
  show: (modal: ContactsModal) => void;
  hide: (modal?: ContactsModal) => void;
};
const initialContext = {
  moreContactsVisible: false,
  visible: "" as ContactsModal,
  show: (modal: ContactsModal) => null,
  hide: (modal: ContactsModal) => null,
};

const initialState: ContactsModalState = {
  moreContactsVisible: false,
};

export interface ContactsModalState {
  visible?: ContactsModal;
  moreContactsVisible?: boolean;
}

type ActionType = {
  type: "show" | "hide" | "showMoreContacts" | "hideMoreContacts";
  payload?: ContactsModal;
};

function reducer(state: ContactsModalState, action: ActionType) {
  switch (action.type) {
    case "show":
      return {
        ...state,
        visible: action.payload,
      };
    case "hide":
      return {
        ...state,
        visible: undefined,
      };
    case "showMoreContacts":
      return {
        ...state,
        moreContactsVisible: true,
      };
    case "hideMoreContacts":
      return {
        ...state,
        moreContactsVisible: false,
      };
    default:
      return state;
  }
}

export const ContactsModalContext = createContext(initialContext);

export const ContactModalContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const show = useCallback(
    (modal: ContactsModal) =>
      modal === "morecontacts"
        ? dispatch({ type: "showMoreContacts", payload: modal })
        : dispatch({ type: "show", payload: modal }),
    [dispatch]
  );

  const hide = useCallback(
    (modal?: ContactsModal) =>
      modal === "morecontacts"
        ? dispatch({ type: "hideMoreContacts" })
        : dispatch({ type: "hide" }),
    [dispatch]
  );

  return (
    <ContactsModalContext.Provider
      value={{
        moreContactsVisible: state.moreContactsVisible,
        visible: state.visible,
        show,
        hide,
      }}
    >
      {children}{" "}
    </ContactsModalContext.Provider>
  );
};

type ContactFiltersContextProps = {
  selected: Contact[];
  chips: Chip[];
  excludedChips: Chip[];
  exceeded?: boolean;
  dispatch: Dispatch<ReactAction>;
};

export const initialFiltersContext = {
  selected: [],
  chips: [],
  excludedChips: [],
  exceeded: false,
  dispatch: () => null,
};

export const ContactFiltersContext = createContext<ContactFiltersContextProps>(
  initialFiltersContext
);

type State = {
  selected: Contact[];
  chips: Chip[];
  excludedChips: Chip[];
  exceeded?: boolean;
};

export function init(initialChips: Chip[]): State {
  return {
    selected: [],
    chips: initialChips,
    excludedChips: [],
    exceeded: false,
  };
}
export const CONTACT_LIMIT = 50;
type ContactFiltersContextProvider = {
  children: React.ReactElement;
  initialChips: Chip[];
  eventId?: string;
};
export const ContactFiltersContextProvider = ({
  children,
  initialChips,
  eventId,
}: ContactFiltersContextProvider) => {
  const [state, dispatch] = useReducer(
    lengthCheckMiddleware({
      reducer: contactsFiltersReducer,
      length: CONTACT_LIMIT,
      entityKey: "selected",
      type: "LIMIT_EXCEEDED",
    }),
    initialChips,
    init
  );
  const reset = useCallback(() => {
    dispatch({ type: "RESET" });
  }, [dispatch]);
  useRouteChange(reset);
  useEffect(() => {
    dispatch({ type: "SET_CHIPS", payload: initialChips });
  }, [initialChips, eventId]);
  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ContactFiltersContext.Provider
      value={{
        selected: state.selected,
        chips: state.chips,
        excludedChips: state.excludedChips,
        exceeded: state.exceeded,
        dispatch,
      }}
    >
      {children}
    </ContactFiltersContext.Provider>
  );
};
