import { SpecialFilters } from "./types";
import { Applicability } from "api/powersearch/peopleSearch";
import { CompanySizeOption } from "../shared/CompanySizes/CompanySizes";
import { PhoneNumberType } from "./ContactInformation/types";
import { phoneNumberTypeOptions } from "./ContactInformation/utils";
import { defaultSourcingFilter, SourcingFilterType } from "lib/localstorage";

export type Actions =
  | { type: "clear-all" }
  | { type: "init"; payload: SourcingFilterType }
  | { type: "applicability"; payload: Applicability }
  | { type: "changeChip"; payload: { type: ChipType; chips: Chip[] } }
  | {
      type: "changeNumber";
      payload: { name: string; value: number };
    }
  | { type: "likelyToChange"; payload: boolean }
  | { type: "companySize"; payload: CompanySizeOption }
  | { type: "veteranLocation"; payload: number }
  | {
      type: "sourcingBooleanChange";
      payload: { type: SourcingBooleanType; value: boolean };
    }
  | { type: "fetch" }
  | { type: "resetFetch" }
  | { type: "pageChange"; payload: number }
  | { type: "phoneTypes"; payload: PhoneNumberType[] }
  | { type: "updatePhoneQuality"; payload: number }
  | { type: "updateEmailQuality"; payload: number }
  | { type: "updateSearchFilter"; payload: FilterState };

export type Page = { page: number };
export type FilterState = SourcingFilterType & SpecialFilters;

export type SourcingState = {
  /**
   * currentState is to save user current search state,
   * it will be passed to searchState once reach the trigger points
   */
  currentState: FilterState;
  /**
   * searchState is for making internet request,
   * it will be trigger when
   *  1. User click submit button
   *  2. User click saved searches
   */
  searchState: FilterState;
  /**
   * Page change will trigger internet request
   */
  page: number;
  /**
   * For showing Splash screen and determining to request api or not
   */
  shouldFetch: boolean;
};

export const sourcingFilterReducer = (
  state: SourcingState,
  action: Actions
): SourcingState => {
  switch (action.type) {
    case "clear-all":
      return {
        currentState: defaultSourcingFilter,
        searchState: defaultSourcingFilter,
        shouldFetch: false,
        page: 1,
      };

    case "init":
      return {
        currentState: { ...state.currentState, ...action.payload },
        searchState: action.payload,
        shouldFetch: false,
        page: 1,
      };

    case "updateSearchFilter": {
      const searchState = {
        ...state.currentState,
        searchState: action.payload,
      };
      return { ...state, searchState };
    }
    case "applicability": {
      const currentState = {
        ...state.currentState,
        applicability: action.payload,
      };
      return { ...state, currentState };
    }
    case "changeChip": {
      const chips = action.payload.chips;
      const currentState = {
        ...state.currentState,
        [action.payload.type]: chips,
      };
      return {
        ...state,
        currentState,
      };
    }
    case "fetch":
      return {
        ...state,
        shouldFetch: true,
      };
    case "resetFetch":
      return {
        ...state,
        shouldFetch: false,
      };
    case "changeNumber": {
      const name = action.payload.name;
      const value = action.payload.value;
      const currentState = {
        ...state.currentState,
        [name]: typeof value === "undefined" ? undefined : Number(value),
      };
      return {
        ...state,
        currentState,
      };
    }
    case "likelyToChange": {
      const currentState = {
        ...state.currentState,
        ["likelyToChange"]: action.payload,
      };
      return { ...state, currentState };
    }
    case "companySize": {
      const curSizes = state.currentState.companySizes;
      const newSizes = action.payload;
      const exists = curSizes.find((ele) => ele === newSizes.value);
      let updateSizes: CompanySizeFilter[] = [];
      if (exists) {
        updateSizes = curSizes.filter((ele) => ele !== newSizes.value);
      } else {
        updateSizes = [...curSizes, newSizes.value] as CompanySizeFilter[];
      }
      const currentState = {
        ...state.currentState,
        companySizes: updateSizes,
      };
      return { ...state, currentState };
    }
    case "veteranLocation": {
      const locationId = action.payload;
      const currentState = {
        ...state.currentState,
        veteransOnly: locationId ? true : false,
        veteranCountryLocationId: locationId,
      };
      // If no location selected then give 0 as id, it will be ignored by api
      return {
        ...state,
        currentState,
      };
    }
    case "sourcingBooleanChange": {
      const status = action.payload.value;
      const currentState = {
        ...state.currentState,
        [action.payload.type]: !status,
      };
      return {
        ...state,
        currentState,
      };
    }
    case "pageChange": {
      return {
        ...state,
        page: action.payload,
      };
    }
    case "phoneTypes": {
      const payload = action.payload;
      const phoneNumberType = state.currentState.phoneNumberType;
      let update: PhoneNumberType[] = [];
      /**
       * If user select 'all' option, all the phone number type will be selected,
       *    - if all the types are selected already, deselect all
       * Else if add it the phone type  if it doesn't exist
       * otherwise remove it
       */

      if (payload.length === phoneNumberTypeOptions.length) {
        phoneNumberType?.length !== phoneNumberTypeOptions.length
          ? (update = phoneNumberTypeOptions)
          : (update = []);
      } else {
        payload.forEach((i) => {
          if (!phoneNumberType || phoneNumberType.indexOf(i) === -1) {
            update.push(i);
            update = phoneNumberType ? [...phoneNumberType, ...update] : update;
          } else {
            update = phoneNumberType.filter(
              (selected) => selected !== i && selected !== "All"
            );
          }
        });
      }
      const currentState = {
        ...state.currentState,
        phoneNumberType: update,
      };
      return {
        ...state,
        currentState,
      };
    }
    case "updatePhoneQuality": {
      const currentState = {
        ...state.currentState,
        phoneNumberScore: action.payload,
      };
      return {
        ...state,
        currentState,
      };
    }
    case "updateEmailQuality": {
      const currentState = {
        ...state.currentState,
        emailScore: action.payload,
      };
      return {
        ...state,
        currentState,
      };
    }
  }
};

export const initialFilterState: SourcingState = {
  currentState: defaultSourcingFilter,
  searchState: defaultSourcingFilter,
  shouldFetch: false, //flag to tell SWR to search
  page: 1,
};
