import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

export const useRouteChange = (fn: () => void) => {
  const router = useRouter();
  const currentRef = useRef<string>(null);
  useEffect(() => {
    if (router.asPath !== currentRef?.current) {
      fn();
    }
    currentRef.current = router.asPath;
  }, [router, fn]);
};
