import { PrettyDecentFile } from "@talentticker/tt-editor";
import {
  SignatureContextProvider,
  useMessagesSignature,
} from "lib/useMessagesSignature";
import { useMessageTemplateContext } from "components/Account/MessageTemplates/hook";
import { MessageTemplatesContextProvider } from "components/Account/MessageTemplates/context";
import { createContext, useContext, FC, useCallback, useEffect } from "react";
import { useMessagingConfiguration } from "./effects";
import { useFeature } from "lib/features";
import { useMachine, useSelector } from "@xstate/react";
import type { Event, SingleOrArray } from "xstate";
import {
  DraftState,
  IMessagingMachineState,
  MessageMachineState,
  MessagingMachine,
  SelectedRecipient,
  MessageMachineActions,
} from "./MessagingMachine";
import { CONTACT_LIMIT } from "components/Contacts/context";
import { useRouter } from "next/router";
import { Recipient } from "./RecipientsMachine";
import { useSWRConfig } from "swr";

export type { MessageRecipient } from "./RecipientsMachine";

const MessageContext = createContext<
  [
    IMessagingMachineState,
    (event: SingleOrArray<Event<MessageMachineActions>>) => void
  ]
>([undefined, undefined]);

export const MessageContextProvider: FC = ({ children }) => {
  const { cache, mutate } = useSWRConfig();

  const [actor, send] = useMachine(MessagingMachine, {
    services: {
      // @ts-ignore -- xstate is a firm believer that never and void are not friends
      async refetchRecipients(context) {
        const dispatchedIds = context.selectedRecipients.map(
          ({ manager_id }) => manager_id
        );
        const peopleSearchRegex = /^v5-powersearch\/people-search/g;
        const keys: string[] = [];
        // @ts-ignore -- good old SWR giving us bad types!
        for (const key of cache.keys()) {
          if (peopleSearchRegex.test(key)) {
            keys.push(key);
          }
        }
        await Promise.all(
          keys.map((k) =>
            mutate(
              k,
              (d) => ({
                ...d,
                data: {
                  ...d.data,
                  results: {
                    ...d.data.results,
                    documents: d.data.results.documents.map(
                      (person: Recipient) => {
                        if (dispatchedIds.includes(person.id)) {
                          return { ...person, in_active_sequence: true };
                        }
                        return person;
                      }
                    ),
                  },
                },
              }),
              false
            )
          )
        );
      },
    },
  });
  return (
    <MessageContext.Provider
      value={[
        // @ts-ignore -- the worst part about xstate is constructing your own types!
        actor,
        send,
      ]}
    >
      <MessageTemplatesContextProvider>
        <SignatureContextProvider>{children}</SignatureContextProvider>
      </MessageTemplatesContextProvider>
    </MessageContext.Provider>
  );
};

type UseMessageContext = {
  state: IMessagingMachineState;
  canUseMessaging?: boolean;
  exceeded?: boolean;
  hasDefaultTemplate?: boolean;
  requiresUpgradeAccount?: boolean;
  requiresConfiguration?: boolean;
  /**
   * The list of currently selected recipients
   */
  recipients: Recipient[];
  /**
   * This is the user selected list of recipient/email combinations to send email to
   * NOTE: The same recipient can be selected multiple times
   */
  selectedRecipients: SelectedRecipient[];

  addRecipients: (recipients: Recipient[]) => void;
  /**
   * Force list of recipients into state
   */
  setRecipients: (recipients: Recipient[]) => void;
  /**
   * Clear specific recipients from the list of selected recipients
   */
  clearRecipients: (recipients: Recipient[]) => void;
  /**
   * Clear error state fron machine
   */
  clearErrors: () => void;
  /**
   * Clear all selected recipients
   */
  clearAllRecipients: () => void;
  /**
   * Update a specific recipient's data after a reveal
   */
  revealRecipient: (recipient: Recipient) => void;
  /**
   * Helper function to check if a given id is in the list of recipients
   */
  isSelected: (id: string) => boolean;
  /**
   *  Helper function to check if all recipients in a list are selected
   */
  allPeopleSelected: (recipients: { id: string }[]) => boolean;
  /**
   * Remove a specific recipient from the selected list
   */
  removeRecipient: (id: string) => void;
  /**
   * Draft state
   */
  draft?: MessageMachineState["draft"];
  hasDraft?: boolean;
  setDraft: (values: Partial<DraftState>) => void;
  /**
   * Resets the draft state
   * Signature will still be included
   */
  clearDraftState: () => void;

  addAttachments: (files: PrettyDecentFile[]) => void;
  removeAttachment: (file: PrettyDecentFile) => void;

  /**
   * Initiate actual sending of email
   */
  sendEmail: () => void;
  /**
   * Function to transition state of machine
   */
  setModalOpen: (isOpen: boolean) => void;
  /**
   * If user has Bullhorn configured,
   * transitions to the emailSelection step
   */
  goToEmailSelection: () => void;
  /**
   * Go to next step in the messaging flow
   */
  next: () => void;
  /**
   * Select the email address for a given recipient to be used in messaging
   */
  selectRecipientEmail: (id: Recipient["id"], email: string) => void;
  /**
   * Deselects the email address for a given recipient to be used in messaging
   */
  deselectRecipientEmail: (id: Recipient["id"], email: string) => void;
  /**
   * Picks the highest scoring email address for a given recipient to be used in messaging
   */
  setRecipientEmailAuto: (id: Recipient["id"]) => void;
};

/**
 * Hook to accesss the current message sending state
 */
export const useMessageContext = (): UseMessageContext => {
  const router = useRouter();

  const { sequencing } = useFeature();
  const { gsuiteEmail, smtpUsername } = useMessagingConfiguration();

  const [actor, send] = useContext(MessageContext);

  const state = actor.context;
  const { draft, selectedRecipients } = state;

  // grab the recipients from the child machine
  const { recipients } = useSelector(
    actor.context.recipientsRef,
    (state) => state.context
  );
  const exceeded = recipients.length > CONTACT_LIMIT;

  const { activeTemplate } = useMessageTemplateContext();
  const { signature } = useMessagesSignature();

  const { messagingConfigured, messagingPermitted } =
    useMessagingConfiguration();

  const isSelected = useCallback(
    (id: string) => !!recipients.find((r) => r.id === id),
    [recipients]
  );

  const allPeopleSelected = useCallback(
    (list: { id: string }[]) =>
      list.every(
        (item) => item && Boolean(recipients.find((r) => r.id === item.id))
      ),
    [recipients]
  );

  const removeRecipient = useCallback(
    (id: string) => send({ type: "REMOVE_RECIPIENT", id }),
    [send]
  );

  const addRecipients = useCallback(
    (recipients: Recipient[]) => send({ type: "ADD_RECIPIENTS", recipients }),
    [send]
  );

  const setRecipients = useCallback(
    (recipients: Recipient[]) => send({ type: "SET_RECIPIENTS", recipients }),
    [send]
  );

  const clearRecipients = useCallback(
    (recipients: Recipient[]) => send({ type: "CLEAR_RECIPIENTS", recipients }),
    [send]
  );

  const clearErrors = useCallback(() => send({ type: "CLEAR_ERRORS" }), [send]);

  const clearAllRecipients = useCallback(
    () => send({ type: "CLEAR_ALL_RECIPIENTS" }),
    [send]
  );

  const setDraft = useCallback(
    (draft: Partial<DraftState>) => send({ type: "UPDATE_DRAFT", draft }),
    [send]
  );

  const clearDraftState = useCallback(
    () => send({ type: "CLEAR_DRAFT" }),
    [send]
  );

  const revealRecipient = useCallback(
    (recipient: Recipient) => send({ type: "REVEAL_RECIPIENT", recipient }),
    [send]
  );

  const addAttachments = useCallback(
    (attachments: PrettyDecentFile[]) =>
      send({ type: "ADD_ATTACHMENTS", attachments }),
    [send]
  );

  const removeAttachment = useCallback(
    (attachment: PrettyDecentFile) =>
      send({ type: "REMOVE_ATTACHMENT", attachment }),
    [send]
  );

  const setModalOpen = useCallback(
    (isOpen: boolean) => send({ type: "SET_OPEN", isOpen }),
    [send]
  );

  const sendEmail = useCallback(() => send({ type: "SEND_EMAIL" }), [send]);

  const goToEmailSelection = useCallback(
    () => send({ type: "GO_TO_EMAIL_SELECTION" }),
    [send]
  );

  const next = useCallback(() => send({ type: "NEXT" }), [send]);

  const selectRecipientEmail = useCallback(
    (id: Recipient["id"], email: string) =>
      send({ type: "SELECT_RECIPIENT_EMAIL", id, email }),
    [send]
  );

  const deselectRecipientEmail = useCallback(
    (id: Recipient["id"], email: string) =>
      send({ type: "DESELECT_RECIPIENT_EMAIL", id, email }),
    [send]
  );

  const setRecipientEmailAuto = useCallback(
    (id: Recipient["id"]) => send({ type: "SET_RECIPIENT_EMAIL_AUTO", id }),
    [send]
  );

  /**
   * Subscribe to config changes, and update them internally to the machine
   * whenever they change
   */
  useEffect(() => {
    send({
      type: "SET_CONFIG",
      config: {
        sequencing,
        gsuiteEmail,
        smtpUsername,
      },
    });
  }, [sequencing, gsuiteEmail, smtpUsername, send]);

  /**
   * We update the internal machine state when the signature changes
   */
  useEffect(() => {
    send({ type: "SET_SIGNATURE", signature });
  }, [signature, send]);

  /**
   * Update the internal draft state when the active template changes
   */
  useEffect(() => {
    if (activeTemplate) {
      const message = activeTemplate.body
        ? `${activeTemplate.body || ""} ${signature || ""}`
        : signature || "";
      send({
        type: "UPDATE_DRAFT",
        draft: {
          subject: activeTemplate.subject,
          message,
          attachments: [],
          images: [],
        },
      });
    }
  }, [activeTemplate, send, signature]);

  /**
   * We need to clear the selected recipients when the route changes
   */
  useEffect(() => {
    router.events.on("routeChangeStart", clearAllRecipients);
    return () => {
      router.events.off("routeChangeStart", clearAllRecipients);
    };
  }, [router.events, clearAllRecipients]);

  return {
    //@ts-ignore -- typegen is throwing off the type of this
    state: actor,
    exceeded,
    // permissions
    canUseMessaging: messagingPermitted && messagingConfigured,
    requiresUpgradeAccount: !messagingPermitted,
    requiresConfiguration: messagingPermitted && !messagingConfigured,
    // drafts
    draft,
    hasDraft: !!draft,
    setDraft,
    clearDraftState,
    // recipients
    recipients,
    selectedRecipients,
    addRecipients,
    setRecipients,
    revealRecipient,
    removeRecipient,
    clearRecipients,
    clearAllRecipients,
    selectRecipientEmail,
    deselectRecipientEmail,
    setRecipientEmailAuto,

    isSelected,
    allPeopleSelected,
    // attachments
    addAttachments,
    removeAttachment,
    // send
    sendEmail,
    // misc
    setModalOpen,
    goToEmailSelection,
    clearErrors,
    next,
  };
};
