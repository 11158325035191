import { requestV2 } from "api/request";
import { SourcingSearchFilters } from "../Form/types";
import { SavedSearchDELETE, SavedSearchPOST } from "./types";

type Args = {
  name: string;
  state: SourcingSearchFilters;
};

export const saveSearch = async ({ name, state }: Args) =>
  await requestV2<SavedSearchPOST>({
    method: "POST",
    url: "/v1-sourcing/searches",
    body: JSON.stringify({ name, state }),
  });
export const deleteSearch = async (id: string) =>
  await requestV2<SavedSearchDELETE>({
    method: "DELETE",
    type: "DELETE",
    url: `/v1-sourcing/searches/${id}`,
  });
