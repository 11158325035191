import { useCallback, useContext } from "react";
import { PhoneNumberType } from "./Form/ContactInformation/types";
import {
  SourcingFilterContext,
  SourcingFilterContextType,
} from "./Form/context";
import { FilterState } from "./Form/reducer";
import { SourcingSearchFilters } from "./Form/types";
import { SavedSearchContext } from "./SavedSearches/context";
import { CompanySizeOption } from "./shared/CompanySizes/CompanySizes";

type UseSourcingFilters = {
  /**
   * Current state of the sourcing filters
   */
  currentFilters: FilterState;
  /**
   * Sourcing filters for search
   */
  searchFilters: FilterState;
  /**
   * Function to refetch the data using the current filters
   */
  fetch: () => void;
  /**
   * Flag for if the state should be refetched from the backend
   */
  shouldFetch: boolean;
  /**
   * Function to deselect a given filter from state
   */
  deselectFilter: (option: SourcingBooleanType | "veteranLocation") => void;
  /**
   * Function to change given chips state
   */
  changeChips: (type: ChipType, chips: Chip[]) => void;
  /**
   * Change a given number filter in state
   */
  changeNumber: (name: string, value: number) => void;
  /**
   * Current page number
   */
  page: number;
  /**
   * Sets the page in filter state
   */
  setPage: (page: number) => void;
  /**
   * Function to set the company size filter
   */
  setCompanySize: (size: CompanySizeOption) => void;
  /**
   * Function to reset current filters state to the default
   * or to the payload if one is provided
   */
  setCurrentFilters?: (payload?: FilterState) => void;
  /**
   * Function to reset search filters state to the default
   * or to the payload if one is provided
   */
  setSearchFilters?: (payload?: FilterState) => void;
  /**
   * Clear all the filter state
   */
  clearAll: () => void;
  /**
   * Function to change number type
   */
  changePhoneNumberType: (paylod: PhoneNumberType[]) => void;
  /**
   * Funtion to change phone number quality score
   */
  changePhoneNumberQuality: (paylod: number) => void;
  /**
   * Funtion to change email quality score
   */
  changeEmailQuality: (paylod: number) => void;
  /**
   * Function to clear all filters back to rawFilters
   */
  clearFilters: () => void;
} & Pick<SourcingFilterContextType, "storedFilters" | "saveFilters">;

/**
 * Hook to store, access and update the current sourcing search filters
 */
export const useSourcingContext = (): UseSourcingFilters => {
  const { state, dispatch, storedFilters, saveFilters } = useContext(
    SourcingFilterContext
  );

  const setCurrentFilters = useCallback(
    (state?: SourcingSearchFilters) => {
      // Initialise the filter if setCurrentFilters() has no params
      dispatch({ type: "init", payload: state });
    },
    [dispatch]
  );

  const setSearchFilters = useCallback(
    (state: SourcingSearchFilters) => {
      dispatch({ type: "updateSearchFilter", payload: state });
    },
    [dispatch]
  );

  const fetch = useCallback(() => {
    dispatch({ type: "fetch" });
  }, [dispatch]);

  const setPage = useCallback(
    (page: number) => {
      dispatch({ type: "pageChange", payload: page });
    },
    [dispatch]
  );

  const deselectFilter = useCallback(
    (option: SourcingBooleanType | "veteranLocation") => {
      if (option === "veteranLocation") {
        dispatch({
          type: "veteranLocation",
          payload: 0,
        });
      } else {
        dispatch({
          type: "sourcingBooleanChange",
          payload: { type: option, value: true },
        });
      }
    },
    [dispatch]
  );

  const changeChips = useCallback(
    (type: ChipType, chips: Chip[]) => {
      dispatch({
        type: "changeChip",
        payload: { type, chips },
      });
    },
    [dispatch]
  );

  const changeNumber = useCallback(
    (name: string, value?: number) => {
      dispatch({
        type: "changeNumber",
        payload: { name, value },
      });
    },
    [dispatch]
  );

  const setCompanySize = useCallback(
    (size: CompanySizeOption) => {
      dispatch({
        type: "companySize",
        payload: size,
      });
    },
    [dispatch]
  );

  const clearAll = useCallback(() => {
    dispatch({
      type: "clear-all",
    });
  }, [dispatch]);

  const changePhoneNumberType = useCallback(
    (paylod: PhoneNumberType[]) => {
      dispatch({
        type: "phoneTypes",
        payload: paylod,
      });
    },
    [dispatch]
  );

  const changePhoneNumberQuality = useCallback(
    (payload: number) => {
      dispatch({ type: "updatePhoneQuality", payload });
    },
    [dispatch]
  );

  const changeEmailQuality = useCallback(
    (payload: number) => {
      dispatch({ type: "updateEmailQuality", payload });
    },
    [dispatch]
  );

  const clearFilters = useCallback(() => {
    dispatch({ type: "clear-all" });
  }, [dispatch]);

  return {
    searchFilters: state.searchState,
    currentFilters: state.currentState,
    page: state.page,
    shouldFetch: state.shouldFetch,
    fetch,
    clearAll,
    changePhoneNumberType,
    changePhoneNumberQuality,
    changeEmailQuality,
    deselectFilter,
    changeChips,
    changeNumber,
    setCompanySize,
    setPage,
    setCurrentFilters,
    setSearchFilters,
    clearFilters,
    saveFilters,
    storedFilters,
  };
};

export const useSavedSearchContext = () => {
  const { state, setState } = useContext(SavedSearchContext);
  return {
    searches: state,
    setSearches: setState,
  };
};
