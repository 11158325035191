import React, { useReducer, createContext, Dispatch, useEffect } from "react";
import { getTemplatesApi } from "api/email-integration";
import { useFeature } from "lib/features";

type ContextProps = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export type MessageTemplate = ReturnTypeAsync<typeof getTemplatesApi>[0];

type State = {
  activeTemplate: MessageTemplate;
  templates: MessageTemplate[];

  formState: {
    name: string;
    subject: string;
    message: string;
    submitting: boolean;
    submitted: boolean;
    edited: boolean;
    operation: "create" | "update";
    editorOverrideValue: string;
  };
};

type Actions =
  | { type: "SET_CREATING" }
  | { type: "RESET_INTERACTIONS" }
  | { type: "SET_EDITED"; payload: boolean }
  | { type: "DELETE_TEMPLATE"; payload: string }
  | { type: "UPDATE_TEMPLATE"; payload: MessageTemplate }
  | { type: "SET_ACTIVE_TEMPLATE"; payload: MessageTemplate }
  | { type: "SET_TEMPLATES"; payload: MessageTemplate[] }
  | { type: "setFormState"; payload: Partial<State["formState"]> };

const getInitialFormState = (): State["formState"] => {
  // Intentionally creating new empty object one each call to make sure useEffects are triggered.
  return {
    name: "",
    subject: "",
    message: "",
    submitting: false,
    submitted: false,
    edited: false,
    operation: "create",
    editorOverrideValue: "",
  };
};

const initialContext: ContextProps = {
  state: {
    templates: [],
    activeTemplate: null,
    formState: getInitialFormState(),
  },
  dispatch: undefined,
};

export const MessageTemplatesContext =
  createContext<ContextProps>(initialContext);

const messageTemplatesReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "SET_CREATING":
      return {
        ...state,
        formState: {
          ...getInitialFormState(),
          operation: "create",
          edited: true,
        },
        activeTemplate: null,
      };
    case "SET_TEMPLATES":
      return {
        ...state,
        templates: action.payload,
      };
    case "DELETE_TEMPLATE":
      return {
        ...state,
        templates: state.templates.filter(
          (template) => template.id !== action.payload
        ),
      };
    case "SET_ACTIVE_TEMPLATE": {
      return {
        ...state,
        formState: {
          ...state.formState,
          edited: true,
          operation: "update",
        },
        activeTemplate: action.payload,
      };
    }
    case "SET_EDITED":
      return {
        ...state,
        formState: {
          ...state.formState,
          edited: action.payload,
        },
      };
    case "UPDATE_TEMPLATE":
      return {
        ...state,
        templates: state.templates.map((template) =>
          template.id === action.payload.id ? action.payload : template
        ),
      };

    case "RESET_INTERACTIONS":
      return {
        ...state,
        activeTemplate: null,
        formState: getInitialFormState(),
      };

    case "setFormState":
      return { ...state, formState: { ...state.formState, ...action.payload } };
    default:
      return state;
  }
};

export const MessageTemplatesContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(
    messageTemplatesReducer,
    initialContext.state
  );

  const { isLoggedIn } = useFeature();

  useEffect(() => {
    async function init() {
      const templates = await getTemplatesApi();
      if (templates && templates.length > 0) {
        dispatch({ type: "SET_TEMPLATES", payload: templates });
      }
    }
    if (isLoggedIn) {
      init();
    }
    // Intentionally init only once upon provider creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessageTemplatesContext.Provider value={{ state, dispatch }}>
      {children}
    </MessageTemplatesContext.Provider>
  );
};
