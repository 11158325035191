import { useFeature } from "lib/features";
import { getSignaturesApi, putSignaturesApi } from "api/email-integration";
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { addSnackbarMessage } from "store/notices/actions";

type State = {
  originalRawSignature: string;
  rawSignature: string;
  originalSignature: string;
  signature: string;
};

type ProviderProps = {
  children: ReactNode;
};

const initialState: State = {
  originalRawSignature: "",
  rawSignature: "",
  originalSignature: "",
  signature: "",
};

type ReducerAction = ["setState", Partial<State>];

const Context = createContext({
  state: initialState,
  dispatch: undefined as Dispatch<ReducerAction>,
});

const Reducer = (state: State, action: ReducerAction): State => {
  return { ...state, ...action[1] };
};

export const SignatureContextProvider = (props: ProviderProps) => {
  const { children } = props;

  const [state, dispatch] = useReducer(Reducer, initialState);

  const { isLoggedIn } = useFeature();

  useEffect(() => {
    if (isLoggedIn) {
      init();
    }
    // Intentionally init only once upon provider creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    const signatureData = await getSignaturesApi();
    const signature =
      signatureData?.content && signatureData.content !== "undefined"
        ? signatureData.content
        : "";

    if (signature) {
      dispatch([
        "setState",
        {
          originalRawSignature: signature,
          rawSignature: signature,

          originalSignature: signature,
          signature: signature,
        },
      ]);
    }
  };

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const useMessagesSignature = () => {
  const { state, dispatch } = useContext(Context);
  const reduxDispatch = dispatch as any as Dispatch<{
    type: string;
    payload: any;
  }>;

  const { signature, rawSignature, originalSignature } = state;

  const undoChanges = () => {
    dispatch([
      "setState",
      {
        signature: originalSignature,
      },
    ]);
  };

  const createSignature = async (rawSignature: string, signature: string) => {
    try {
      await putSignaturesApi(rawSignature, signature);
      dispatch(["setState", { signature }]);

      reduxDispatch(
        addSnackbarMessage({
          message: `Successfully created signature`,
          autoHideDuration: 2000,
          variant: "success",
        })
      );
    } catch (error) {
      reduxDispatch(
        addSnackbarMessage({
          message: `Failed to create signature`,
          autoHideDuration: 1000,
          variant: "error",
        })
      );
    }
  };

  return {
    createSignature,
    rawSignature,
    signature,
    undoChanges,
  };
};
