import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { getUserPipelineData } from "store/pipeline/selectors";
import some from "lodash/some";
import {
  CONTACT_LIMIT,
  ContactFiltersContext,
  ContactsModalContext,
  ContactsModalState,
} from "./context";
import { useMessageContext } from "components/Messaging/MessagingContext";

type UseContactsModalReturn = {
  show: (modal: ContactsModal) => void;
  hide: (modal?: ContactsModal) => void;
} & ContactsModalState;
/**
 * Hook to control the visibility and props of modals in the EventInformation view
 * Only one modal should be visible at a time, so the states are coupled
 */

export const useContactsModal = (): UseContactsModalReturn => {
  const { show, hide, visible, ...state } = useContext(ContactsModalContext);
  return {
    ...state,
    visible,
    show,
    hide,
  };
};

export enum ContactsModal {
  Message = "message",
  PipelineSave = "pipeline",
  MoreContacts = "morecontacts",
  SavedSearches = "savedSearches",
}

type Entity = { id: string };

function taskSorter(a: Entity, b: Entity) {
  return a.id > b.id ? 1 : -1;
}

export function useTasks() {
  const { columns, tasks } = useSelector(getUserPipelineData);
  const cols = Object.values(columns).sort(taskSorter);
  const res = cols.flatMap((col) =>
    col.taskIds
      .map((taskId) => tasks[taskId])
      .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
  );
  return res;
}

function addToPipelineReducer(
  state: {
    /**
     * List of pipeline ids currently being added to
     */
    adding: string[];
    /**
     * Successfully added pipeline ids
     */
    added: string[];
  },
  action:
    | { type: "adding" | "added" | "add_error"; payload: string }
    | { type: "reset_added" }
) {
  switch (action.type) {
    case "adding":
      return { ...state, adding: [...state.adding, action.payload] };
    case "added":
      return {
        adding: state.adding.filter((a) => a !== action.payload),
        added: state.added.includes(action.payload)
          ? state.added
          : [...state.added, action.payload],
      };
    case "add_error":
      return {
        ...state,
        adding: state.adding.filter((a) => a !== action.payload),
      };
    case "reset_added":
      return {
        ...state,
        added: [],
      };
  }
}
export function useAddToPipeline() {
  const [state, dispatch] = useReducer(addToPipelineReducer, {
    adding: [],
    added: [],
  });
  const adding = (id: string) => dispatch({ type: "adding", payload: id });
  const added = (id: string) => dispatch({ type: "added", payload: id });
  const addError = (id: string) => dispatch({ type: "add_error", payload: id });

  const resetAdded = useCallback(
    () => dispatch({ type: "reset_added" }),
    [dispatch]
  );
  return [
    state,
    {
      adding,
      added,
      addError,
      resetAdded,
    },
  ] as const;
}

type State = {
  // TODO: switch to using RecipientsMachine for managing selected, rather than internally here
  selected: Contact[];
  chips: Chip[];
  excludedChips: Chip[];
  exceeded?: boolean;
};

type ReducerAction =
  | { type: "ADD-CHIP"; payload: Chip }
  | { type: "CLEAR-CHIP"; payload: string }
  | { type: "CLEAR-ALL" }
  | { type: "DESELECT-ALL"; payload: Contact }
  | { type: "DESELECT"; payload: string }
  | { type: "SELECT"; payload: Contact[] }
  | { type: "SET_CHIPS"; payload: Chip[] }
  | { type: "TOGGLE"; payload: Contact }
  | { type: "TOGGLE_ALL"; payload: Contact }
  | { type: "TOGGLE_EXCLUDE"; payload: Chip }
  | { type: "LIMIT_EXCEEDED"; payload: Contact }
  | { type: "RESET" };

export const isSelected = (state, action) =>
  state.selected.findIndex((contact) => contact?.id === action.payload?.id) >
  -1;

export function contactsFiltersReducer(
  state: State,
  action: ReducerAction
): State {
  switch (action.type) {
    case "ADD-CHIP":
      return {
        ...state,
        chips: [
          ...state.chips.filter(
            (chip) => chip?.es_id !== action.payload?.es_id
          ),
          action.payload,
        ],
      };

    case "CLEAR-CHIP":
      return {
        ...state,
        chips: state.chips.reduce<Chip[]>((prev, curr) => {
          if (curr?.es_id !== action.payload) {
            return prev.concat(curr);
          }
          return prev;
        }, []),
      };

    case "SET_CHIPS":
      return { ...state, chips: action.payload, selected: [] };

    case "CLEAR-ALL":
      return { ...state, chips: [], selected: [] };

    case "SELECT":
      return { ...state, selected: [...state.selected, ...action.payload] };

    case "DESELECT":
      return {
        ...state,
        selected: state.selected.filter((s) => s?.id !== action.payload),
      };

    case "DESELECT-ALL":
      if (isSelected(state, action)) {
        //if its selected.. filter it out
        return {
          ...state,
          selected: state.selected.filter(
            (contact) => contact.id !== action.payload.id
          ),
        };
      }
      return { ...state };

    case "TOGGLE_EXCLUDE": {
      if (state.excludedChips.some((i) => i?.es_id === action.payload?.es_id)) {
        return {
          ...state,
          excludedChips: state.excludedChips?.filter(
            (chip) => chip?.es_id !== action?.payload.es_id
          ),
        };
      }
      return {
        ...state,
        excludedChips: [...state.excludedChips, action.payload],
      };
    }

    case "LIMIT_EXCEEDED": {
      if (isSelected(state, action)) {
        //check to see if the current contact is getting removed and remove if so
        return {
          ...state,
          exceeded: state.selected.length - 1 > CONTACT_LIMIT,
          selected: state.selected.filter((s) => s?.id !== action.payload.id),
        };
      }
      return {
        ...state,
        exceeded: true,
        selected: [...state.selected, action.payload], // add the last contact in as we hit the limit
      };
    }

    case "TOGGLE_ALL": {
      if (isSelected(state, action)) {
        //if its already selected ignore it
        return {
          ...state,
        };
      }
      return { ...state, selected: [...state.selected, action.payload] };
    }

    case "TOGGLE": {
      if (isSelected(state, action)) {
        return {
          ...state,
          selected: state.selected.filter((s) => s?.id !== action.payload.id),
        };
      }
      return { ...state, selected: [...state.selected, action.payload] };
    }

    case "RESET": {
      return {
        exceeded: false,
        selected: [],
        chips: [],
        excludedChips: [],
      };
    }
  }
}

export function useContactFilters() {
  const { dispatch, ...state } = useContext(ContactFiltersContext);
  const { setRecipients } = useMessageContext();

  const isExcluded = useCallback(
    (item: Chip) => some(state.excludedChips, item),
    [state.excludedChips]
  );

  const selectAllStatus = useCallback(
    (cards: Contact[]) => {
      return (
        cards?.every((card) => state.selected.some((s) => s.id === card.id)) ||
        false
      );
    },
    [state.selected]
  );

  const handleDeselectAll = useCallback(
    (cards: Contact[]) => {
      cards.forEach((card) => {
        dispatch({ type: "DESELECT-ALL", payload: card });
      });
    },
    [dispatch]
  );

  const handleSelectAll = useCallback(
    (cards: Contact[]) => {
      // check if the cards have already been selected
      if (selectAllStatus(cards)) {
        handleDeselectAll(cards);
      } else {
        cards.forEach((card) => {
          dispatch({ type: "TOGGLE_ALL", payload: card });
        });
      }
    },
    [dispatch, handleDeselectAll, selectAllStatus]
  );

  const handleDeselect = useCallback(
    (id: string) => {
      dispatch({ type: "DESELECT", payload: id });
    },
    [dispatch]
  );

  const handleAddChip = useCallback(
    (chip: Chip) => {
      dispatch({ type: "ADD-CHIP", payload: chip });
    },
    [dispatch]
  );

  const handleClearChip = useCallback(
    (chip: Chip, excluded: boolean) => (e: React.MouseEvent) => {
      e.stopPropagation();
      if (excluded) {
        dispatch({ type: "TOGGLE_EXCLUDE", payload: chip });
      }
      dispatch({ type: "CLEAR-CHIP", payload: chip.es_id });
    },
    [dispatch]
  );

  const handleClearAll = useCallback(
    (event: React.MouseEvent) => {
      // need to stop this click propagating the click away listener
      event.stopPropagation();
      dispatch({ type: "CLEAR-ALL" });
    },
    [dispatch]
  );

  const toggleContact = useCallback(
    (contact: Contact) => {
      dispatch({ type: "TOGGLE", payload: contact });
    },
    [dispatch]
  );

  const handleExclusion = useCallback(
    (chip: Chip) => {
      // need to stop this click propagating the click away listener
      return (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch({ type: "TOGGLE_EXCLUDE", payload: chip });
      };
    },
    [dispatch]
  );

  /**
   * Force subscribe messaging machine to selected state
   * TODO: claim responsibility for recipients in machine, NOT here
   */
  useEffect(() => {
    setRecipients(state.selected);
  }, [state.selected, setRecipients]);

  return {
    state,
    handleSelectAll,
    handleDeselect,
    handleAddChip,
    handleClearChip,
    handleClearAll,
    toggleContact,
    handleExclusion,
    selectAllStatus,
    isExcluded,
  };
}
