import { PERMISSIONS } from "constants/index";
import { NextRouter } from "next/router";

const protectedRoute = (
  router: NextRouter,
  permissions: string[],
  requiredPermission: string,
  redirectIfNotAuth?: boolean
) => {
  if (hasRequiredPermissions(permissions, requiredPermission)) {
    return true;
  } else {
    if (redirectIfNotAuth) {
      Routing.redirectToLoginWithReturnUrl(router);
    }

    return false;
  }
};

const hasRequiredPermissions = (
  permissions: string[],
  requiredPermission: string
) => permissions && permissions.includes(requiredPermission);

export const Authorization = {
  TalentSourcing: (
    router: NextRouter,
    permissions: string[],
    redirectIfNotAuth: boolean
  ): boolean => {
    const requiredPermission = PERMISSIONS.READ_TALENT_SOURCING;

    return protectedRoute(
      router,
      permissions,
      requiredPermission,
      redirectIfNotAuth
    );
  },
  MarketMapping: (
    router: NextRouter,
    permissions: string[],
    redirectIfNotAuth?: boolean
  ): boolean => {
    const requiredPermission = PERMISSIONS.READ_MARKET_MAPPING;

    return protectedRoute(
      router,
      permissions,
      requiredPermission,
      redirectIfNotAuth
    );
  },
  Pipeline: (
    router: NextRouter,
    permissions: string[],
    redirectIfNotAuth?: boolean
  ): boolean => {
    return protectedRoute(
      router,
      permissions,
      PERMISSIONS.READ_PIPELINE_TASK,
      redirectIfNotAuth
    );
  },
  SendMessage: (permissions: string[]): boolean => {
    return hasRequiredPermissions(permissions, PERMISSIONS.SEND_EMAIL);
  },
};

export const Routing = {
  getAsPath: (router: NextRouter): string => {
    return encodeURIComponent((router && router.asPath) || "");
  },
  redirectToLoginWithReturnUrl: (router: NextRouter): void => {
    router.push(`/home?r=${Routing.getAsPath(router)}`);
  },
};

export const AuthorizationMessages = {
  FeatureIsAnAddon: "Messaging is an addon, please get in touch to upgrade",
};
