import * as Sentry from "@sentry/browser";
import { requestUrl } from "utils/api-request";
import request from "utils/request";

export const getTemplatesApi = async () => {
  const response = await request({
    url: requestUrl({
      service: "v2-email-integration",
      action: "templates",
    }),
    options: {
      method: "GET",
    },
    auth: true,
  });

  if (!response?.data?.templates) {
    return [];
  }

  const data = response.data.templates as {
    id: string;
    name: string;
    subject: string;
    body: string; // Deprecated by the raw functionality 2021-10-15
    default: boolean;
  }[];

  return (data || []).map((item) => {
    let body = "";

    try {
      body = window.decodeURIComponent(item.body);
    } catch (err) {
      Sentry.captureMessage(`Failed to parse template with id ${item.id}`);
      Sentry.captureException(err);
    }

    return {
      ...item,
      body: body,
    };
  });
};
