import * as Sentry from "@sentry/browser";
import { requestUrl } from "utils/api-request";
import request from "utils/request";
import { getTemplatesApi } from "./getTemplatesApi";

type Data = Omit<ReturnTypeAsync<typeof getTemplatesApi>[0], "id" | "default">;

export const postTemplatesApi = async (data: Data) => {
  try {
    await request({
      url: requestUrl({
        service: "v2-email-integration",
        action: `templates`,
      }),
      options: {
        method: "POST",
        body: JSON.stringify({
          ...data,
          body: window.encodeURIComponent(data.body), // Deprecated by the raw functionality 2021-10-15
          body_raw: window.encodeURIComponent(data.body),
        }),
      },
      auth: true,
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};
