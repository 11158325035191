import styled from "@emotion/styled";
import { Card, Box } from "@mui/material";
import Button from "components/atoms/Button";

export const SavedSearchesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    font-size: 16px;
    padding: 16px;
  }
`;
export const Paper = styled(Box)`
  text-align: center;
  margin: 0 auto;
`;

export const SearchContainer = styled.div`
  display: inline;
  position: relative;
  float: right;

  .search-now {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
  }
`;

export const SavedSearchesButtonContainer = styled.div`
  display: flex;
  margin-top: 6px;
  justify-content: end;
`;

export const SearchesButton = styled(Button)`
  height: 40px;
  font-size: 16px;
  justify-content: flex-start;

  &:disabled {
    background-color: #a3a4aa !important;
    color: #fff;
    opacity: 1;
  }
`;

export const SavedCard = styled(Card)`
  height: 120px;
  min-width: 382px;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 10px !important;
  border: solid 1px ${({ theme }) => theme.colors.secondary};

  h4 {
    margin: 0 0 8px;
  }

  .MuiIconButton-root {
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      background-color: white;
      color: ${({ theme }) => theme.colors.highlightHover};
    }

    :active {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewMore = styled.a`
  font-size: 14px;
  color: #1e88bd;
`;
