import * as Sentry from "@sentry/browser";
import { requestUrl } from "utils/api-request";
import request from "utils/request";

export const getSignaturesApi = async () => {
  const response = await request({
    url: requestUrl({
      service: "v2-email-integration",
      action: "signature",
    }),
    options: {
      method: "GET",
    },
    auth: true,
  });

  const data = response.data as {
    content_raw: string;
    content: string; // Deprecated by the raw functionality 2021-10-15
    timestamp_created: Date;
    timestamp_updated: Date;
    user_id: string;
  };

  if (!data) {
    return null;
  }

  let content = "";
  let contentRaw: string;

  try {
    content = window.decodeURIComponent(data.content);
  } catch (err) {
    Sentry.captureMessage(`Failed to parse signature for user ${data.user_id}`);
    Sentry.captureException(err);
    return null;
  }

  return {
    ...data,
    content: content,
    content_raw: contentRaw,
  };
};
